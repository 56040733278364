import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Img, WmkImage } from "wmk-image";
import { BlockImageCopyQuery } from "../../fragments/NodeBlockImageCopyFields";
import { Typography } from "../ui/Typography";
import Markdown from "markdown-to-jsx";
import { colors } from "../ui/colors";

const StyledContainer = styled(Container)<{
  $direction?: string;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${colors.black.getHex()};
  color: ${colors.white.getHex()};
    ${({ $direction }) =>
      $direction === `Right`
        ? `.column:nth-child(1) {order: 1;}
    .column:nth-child(2) {order: 2;}`
        : `.column:nth-child(1) {order: 2;}
    .column:nth-child(2) {order: 1;}`};
  }
  @media only screen and (max-width: 768px) {
    ${({ $direction }) =>
      $direction === `Right`
        ? `.column:nth-child(1) {order: 2;}
  .column:nth-child(2) {order: 1;}`
        : `.column:nth-child(1) {order: 2;}
  .column:nth-child(2) {order: 1;}`};
  }
  .link-target {
    position: absolute;
    top: -150px;
  }
`;

const TextCol = styled(Col)<{ $carousel?: boolean }>`
  padding: 3rem 4rem;
  h2 {
    margin-bottom: 1rem !important;
  }
  @media only screen and (min-width: 767px) and (max-width: 1000px) {
    h2 {
      font-size: 28px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: ${({ $carousel }) => ($carousel ? `3rem` : `3rem 5vw`)};
  }
`;

const MarkdownWrapper = styled.div`
  strong {
    font-weight: bold;
  }
  .p {
    margin-bottom: 1rem;
  }
  li > .p {
    margin-bottom: 0.2rem;
  }
`;

const ImageCopy = ({
  imageCopyData,
  id,
  inCarousel
}: {
  imageCopyData: BlockImageCopyQuery;
  id: string;
  inCarousel?: boolean;
}) => {
  const { imagePosition } = imageCopyData;

  const ModifiedLi = ({ children, ...props }) => (
    <li {...props}>
      <Typography.P>{children}</Typography.P>
    </li>
  );

  return (
    <StyledContainer fluid $direction={imagePosition}>
      <div className="link-target" id={id} />
      <Row className="ordered-row">
        <TextCol md={5} className="column" $carousel={inCarousel}>
          {imageCopyData.header && <Typography.H2>{imageCopyData?.header}</Typography.H2>}
          {imageCopyData.content && <MarkdownWrapper>
            <Markdown
              options={{
                overrides: {
                  p: {
                    component: Typography.P
                  },
                  li: {
                    component: ModifiedLi
                  }
                }
              }}>
              {imageCopyData?.content?.content}
            </Markdown>
          </MarkdownWrapper>}
        </TextCol>
        <Col
          md={7}
          className="column"
          style={{ paddingLeft: 0, paddingRight: 0 }}>
          <WmkImage image={new Img(imageCopyData.image)} />
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default ImageCopy;
